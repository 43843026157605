import React from "react";
import { useCallback, useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Create,
  Filter,
  NumberInput,
  DateField,
  AutocompleteInput,
  ReferenceField,
  NumberField,
  required,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  useDataProvider,
  downloadCSV,
  BooleanInput,
  useListController,
} from "react-admin";
//import Typography from '@material-ui/core/Typography';
import TotaleCantina from "./TotaleCantina";
import orange from "@material-ui/core/colors/orange";
import { green, red } from "@material-ui/core/colors";
import { Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Input, TextField as TextInputField } from "@mui/material";

import { cloneElement } from "react";
import { jsPDF } from "jspdf";
import { Print } from "@material-ui/icons";
import jsonExport from "jsonexport/dist";

const rowStyle = (record, index) => {
  let style = {};

  if (record.IdCliente === 51 && record.Umbreleer === true) {
    style = {
      ...style,
      backgroundColor: green[100], // Imposta lo sfondo verde
    };
  }

  if (record.Qta === 0) {
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  }

  if (record.Qta <= record.QtaMinimaAlert) {
    return {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  }

  return style;
};

//FILTRO
const StockFilter = (props) => {
  let { filterValues, setFilters } = useListContext();
  let [searchValue, setSearchValue] = useState(filterValues.q || "");

  const cleanFilters = (filters) => {
    // Crea una copia pulita dei filtri
    const cleanedFilters = { ...filters };

    // Sostituisci `search` con `q` (se esiste)
    if (cleanedFilters.search) {
      cleanedFilters.q = cleanedFilters.search;
      delete cleanedFilters.search;
    }

    // Rimuovi eventuali `search` annidati
    Object.keys(cleanedFilters).forEach((key) => {
      if (key === "search") {
        delete cleanedFilters[key];
      } else if (typeof cleanedFilters[key] === "object") {
        cleanedFilters[key] = cleanFilters(cleanedFilters[key]); // Ricorsione
      }
    });

    // Non includere `page` nei filtri puliti
    if ("page" in cleanedFilters) {
      delete cleanedFilters.page;
    }

    return cleanedFilters;
  };

  useEffect(() => {
    const cleanedFilters = cleanFilters(filterValues);

    // Applica i filtri solo se ci sono modifiche reali
    if (JSON.stringify(filterValues) !== JSON.stringify(cleanedFilters)) {
      setFilters({
        ...cleanedFilters,
        page: filterValues.page, // Mantieni la pagina corrente
      });
    }
  }, [filterValues, setFilters]);

  useEffect(() => {
    setSearchValue(filterValues.q || "");
  }, [filterValues.q]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    if (value === "") {
      const cleanedFilters = cleanFilters(filterValues);
      setFilters({
        ...cleanedFilters,
        q: "", // Rimuovi il filtro `q`
      });
    }
  };

  const handleSearchClick = () => {
    const cleanedFilters = cleanFilters(filterValues);

    // Imposta i filtri senza includere `page` nell'oggetto `filter`
    setFilters({
      ...cleanedFilters,
      q: searchValue,
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(); // Chiama la funzione di ricerca se viene premuto Invio
    }
  };

  return (
    <Filter {...props}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" alwaysOn>
        <Box display="flex" flexDirection="row" alignItems="center">
          <TextInputField id="outlined-basic" label="Cerca vino" value={searchValue} variant="filled" name="search" onChange={handleInputChange} onKeyPress={handleKeyPress} />
          <Button onClick={handleSearchClick} variant="contained" color="primary" style={{ marginLeft: "8px", height: "48px" }}>
            <SearchIcon />
          </Button>
        </Box>

        <Typography variant="body2" color={"gray"} gutterBottom>
          Premi Invio sulla tastiera o clicca sul pulsante con la lente d'ingrandimento per cercare un vino
        </Typography>
      </Box>
    </Filter>
  );
};
let onLoading = false;
// aggiungere in tabella vinistock se è modificato oppure no! Impostare stile in rowStyle controllando il qrcode
export const ViniStock = (props) => {
  const controllerProps = useListController(props);
  return (
    <Grid container>
      <Grid item xs={12}>
        <TotaleCantina />
      </Grid>

      <Grid item xs={12}>
        <List
          bulkActionButtons={false}
          title="Lista dei vini in cantina"
          filters={<StockFilter />}
          sort={{ field: "DataStock", order: "ASC" }}
          pagination={controllerProps.isFetching ? false : undefined}
          actions={<ListActions />}
          {...props}>
          {controllerProps.isFetching ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", padding: 2 }}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Datagrid rowClick="edit" rowStyle={rowStyle}>
              <TextField source="IdVinoStock" label="Id Stock" />
              <ReferenceField label="Vino" source="IdVino" reference="Vini" sortable={false}>
                <TextField source="NomeVino" />
              </ReferenceField>
              <TextField source="Annata" label="Annata" />
              <TextField source="NomeProduttore" label="Produttore" />
              <NumberField source="Qta" label="Qtà" />
              <NumberField
                source="Costo"
                label="Costo"
                options={{
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "EUR",
                }}
              />
              <NumberField
                source="CostoTotale"
                label="Costo Totale"
                sortable={false}
                options={{
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "EUR",
                }}
              />

              <DateField source="DataStock" label="Data" />
              <EditButton />
            </Datagrid>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

const ValidateEditVinoStock = (values) => {
  const errors = {};
  if (!values.IdVinoStock) {
    errors.IdVinoStock = ["Campo obbligatorio"];
  } else if (values.IdVinoStock.length < 2) {
    errors.IdVinoStock = ["Nome troppo corto, non valido"];
  }
  return errors;
};

//const optionRenderer = vino => '${vino.NomeVino} | ${vino.NomeProduttore}';
export const EditVinoStock = (props) => (
  <Edit title="Modifica Cantina" {...props} undoable={false}>
    <SimpleForm submitOnEnter={true} validate={ValidateEditVinoStock} redirect="list">
      <TextField label="Vino" source="VinoEProduttore" />
      <NumberInput disabled source="Qta" label="Quantità in cantina" />
      <NumberInput
        label="Quantità"
        source="QtaToAdd"
        inputProps={{
          step: 1,
          min: 1,
          placeholder: "Quantita'",
        }}
        helperText="Usare il segno meno se si desidera diminuire la quantita'"
        validate={[required()]}
        initialValue={1}
      />

      <ConditionalNumberInput />
    </SimpleForm>
  </Edit>
);

const ValidateCreateVinoStock = (values) => {
  const errors = {};
  if (!values.IdVino) {
    errors.IdVino = ["Campo obbligatorio"];
  }
  return errors;
};

export const CreateVinoStock = (props) => (
  <Create title="Aggiungi a Cantina" {...props} undoable={false} redirect={"list"}>
    <SimpleForm submitOnEnter={true} validate={ValidateCreateVinoStock}>
      <ReferenceInput source="IdVino" label="Vino" reference="Vini" validate={[required()]}>
        <AutocompleteInput optionText="VinoEProduttore" source="VinoEProduttore" reference="Vini" />
      </ReferenceInput>
      <NumberInput
        label="Quantità"
        source="QtaToAdd"
        inputProps={{
          step: 1,
          min: 1,
          placeholder: "Quantita'",
        }}
        validate={[required()]}
        initialValue={1}
      />
      <ConditionalNumberInput />
    </SimpleForm>
  </Create>
);

const ConditionalNumberInput = () => {
  const qrCode = localStorage.getItem("qrcode");
  // MODIFICARE CON QR DI UMBRELEER 2838023A778DFAECDC212708F721B788
  if (qrCode && qrCode === "2838023A778DFAECDC212708F721B788") {
    return <BooleanInput source="Umbreleer" label="Segna come modificato" />;
  }

  return null; // Do not render anything if client ID is not 1
};

const ListActions = (props) => {
  const { className, filters, maxResults, sort, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basepath, showFilter, total } = useListContext(props);
  const dataProvider = useDataProvider();
  const handleClick = useCallback((event) => {
    dataProvider
      .getList(resource, {
        sort: { field: "NomeTipoVino", order: "ASC" },
        pagination: { page: 1, perPage: 3000 },
      })
      .then(({ data }) => print(data))
      .catch((error) => {
        console.error(error);
      });
  });
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basepath={basepath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} exporter={exporter} />
      <Button onClick={handleClick} label="Stampa">
        <Print />
      </Button>
    </TopToolbar>
  );
};

const print = (data) => {
  const formatter = new Intl.NumberFormat("it-IT", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  data.forEach(function (p) {
    p.Prezzo = formatter.format(p.Prezzo);
    if (p.Annata == null) {
      p.Annata = "";
    }
  });
  var headers = createHeaders();
  var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
  doc.setFontSize(8);
  doc.table(1, 1, data, headers, { autoSize: true });
  doc.autoPrint();
  doc.output("dataurlnewwindow");
};

function createHeaders() {
  var keys = ["NomeTipoVino", "NomeVino", "Annata", "NomeProduttore", "Prezzo"];
  /*    
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
        result.push({
        'id' : keys[i],
        'name': keys[i],
        'prompt': keys[i],
        'align': keys[i] === 'Costo' ? 'right' : 'left',
        'padding': 1,
        'width':80
        });
    }*/
  var result = [];
  result.push({
    id: "NomeTipoVino",
    name: "NomeTipoVino",
    prompt: "Tipo",
    align: "left",
    padding: 1,
    //'width':80
  });
  result.push({
    id: "NomeVino",
    name: "NomeVino",
    prompt: "Vino",
    align: "left",
    padding: 1,
    //'width':80
  });
  result.push({
    id: "Annata",
    name: "Annata",
    prompt: "Anno",
    align: "left",
    padding: 1,
    //'width':80
  });
  /*result.push({
        'id' : 'NomeProduttore',
        'name': 'NomeProduttore',
        'prompt': 'Produttore',
        'align': 'left',
        'padding': 1,
        'width':80
        });*/
  result.push({
    id: "Prezzo",
    name: "Prezzo",
    prompt: "Prezzo",
    align: "right",
    padding: 1,
    //'width':80
  });

  return result;
}

// const exporter = (records) => {
//   const qrCode = localStorage.getItem("qrcode");
//   console.log(qrCode)
//   /*const data = records.map(record => ({
//         id : record.id.toString().replace('.',','),
//         IdVinoStock : record.IdVinoStock.toString().replace('.',','),
//         StatoRecord : record.StatoRecord.toString().replace('.',','),
//         IdVino : record.IdVino.toString().replace('.',','),
//         Qta : record.Qta.toString().replace('.',','),
//         QtaMinimaAlert : record.QtaMinimaAlert.toString().replace('.',','),
//         QtaToAdd : record.QtaToAdd.toString().replace('.',','),
//         DataStock : record.DataStock,
//         IdCliente : record.IdCliente.toString().replace('.',','),
//         NomeVino : record.NomeVino,
//     }));*/
//   const data = records.map((record) => ({
//     IdVino: record.IdVino.toString(),
//     IdVinoStock: record.IdVinoStock.toString(),
//     Vino: record.NomeVino,
//     NomeTipoVino: record.NomeTipoVino,
//     Annata: record.Annata,
//     Produttore: record.NomeProduttore,
//     NomeRegione : record.NomeRegione,
//     Vitigni : record.VitigniString,
//     Qta: record.Qta.toString().replace(".", ","),
//     Prezzo : record.Prezzo,
//     Costo: record.Costo.toString().replace(".", ","),
//     CostoTotale: record.CostoTotale.toString().replace(".", ","),
//   }));
//   jsonExport(
//     data,
//     {
//       headers: ["IdVino", "IdVinoStock", "Vino", "NomeTipoVino", "Annata", "Produttore", "Qta", "Costo", "CostoTotale"],
//       rowDelimiter: ";",
//     },
//     (err, csv) => {
//       const BOM = "\uFEFF";
//       downloadCSV(`${BOM} ${csv}`, "Cantina");
//     }
//   );
// };

const exporter = (records) => {
  const qrCode = localStorage.getItem("qrcode");
  // osteria 99 
  const includeExtraFields = qrCode === "E2C420D928D4BF8CE0FF2EC19B371514";

  const data = records.map((record) => {
    const baseFields = {
      IdVino: record.IdVino.toString(),
      IdVinoStock: record.IdVinoStock.toString(),
      Vino: record.NomeVino,
      NomeTipoVino: record.NomeTipoVino,
      Annata: record.Annata,
      Produttore: record.NomeProduttore,
      Qta: record.Qta.toString().replace(".", ","),
      Costo: record.Costo.toString().replace(".", ","),
      CostoTotale: record.CostoTotale.toString().replace(".", ","),
    };

    const extraFields = includeExtraFields
      ? {
          NomeRegione: record.NomeRegione,
          Vitigni: record.VitigniString,
          Prezzo: record.Prezzo,
        }
      : {};

    return { ...baseFields, ...extraFields };
  });

  const headers = ["IdVino", "IdVinoStock", "Vino", "NomeTipoVino", "Annata", "Produttore", ...(includeExtraFields ? ["NomeRegione", "Vitigni", "Prezzo"] : []), "Qta", "Costo", "CostoTotale"];

  jsonExport(
    data,
    {
      headers: headers,
      rowDelimiter: ";",
    },
    (err, csv) => {
      const BOM = "\uFEFF";
      downloadCSV(`${BOM}${csv}`, "Cantina");
    }
  );
};

export default ViniStock;
